"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportsPaymentRequestApi = exports.supportsPopups = exports.hasSoftwareKeyboard = exports.isSilk = exports.isSamsungBrowser = exports.isOpera = exports.isMobileFirefox = exports.isIpadOS = exports.isIosWKWebview = exports.isIosWebview = exports.isIosUIWebview = exports.isIosSafari = exports.isSafari = exports.isIosGoogleSearchApp = exports.isIosFirefox = exports.isIos = exports.isFirefox = exports.isEdge = exports.isIe11 = exports.isIe10 = exports.isIe9 = exports.isIe = exports.isDuckDuckGo = exports.isChrome = exports.isChromeOS = exports.isAndroid = void 0;
var isAndroid = require("./is-android");
exports.isAndroid = isAndroid;
var isChromeOS = require("./is-chrome-os");
exports.isChromeOS = isChromeOS;
var isChrome = require("./is-chrome");
exports.isChrome = isChrome;
var isDuckDuckGo = require("./is-duckduckgo");
exports.isDuckDuckGo = isDuckDuckGo;
var isIe = require("./is-ie");
exports.isIe = isIe;
var isIe9 = require("./is-ie9");
exports.isIe9 = isIe9;
var isIe10 = require("./is-ie10");
exports.isIe10 = isIe10;
var isIe11 = require("./is-ie11");
exports.isIe11 = isIe11;
var isEdge = require("./is-edge");
exports.isEdge = isEdge;
var isFirefox = require("./is-firefox");
exports.isFirefox = isFirefox;
var isSafari = require("./is-safari");
exports.isSafari = isSafari;
var isIos = require("./is-ios");
exports.isIos = isIos;
var isIosFirefox = require("./is-ios-firefox");
exports.isIosFirefox = isIosFirefox;
var isIosGoogleSearchApp = require("./is-ios-google-search-app");
exports.isIosGoogleSearchApp = isIosGoogleSearchApp;
var isIosSafari = require("./is-ios-safari");
exports.isIosSafari = isIosSafari;
var isIosUIWebview = require("./is-ios-uiwebview");
exports.isIosUIWebview = isIosUIWebview;
var isIosWebview = require("./is-ios-webview");
exports.isIosWebview = isIosWebview;
var isIosWKWebview = require("./is-ios-wkwebview");
exports.isIosWKWebview = isIosWKWebview;
var isIpadOS = require("./is-ipados");
exports.isIpadOS = isIpadOS;
var isMobileFirefox = require("./is-mobile-firefox");
exports.isMobileFirefox = isMobileFirefox;
var isOpera = require("./is-opera");
exports.isOpera = isOpera;
var isSamsungBrowser = require("./is-samsung");
exports.isSamsungBrowser = isSamsungBrowser;
var isSilk = require("./is-silk");
exports.isSilk = isSilk;
var hasSoftwareKeyboard = require("./has-software-keyboard");
exports.hasSoftwareKeyboard = hasSoftwareKeyboard;
var supportsPopups = require("./supports-popups");
exports.supportsPopups = supportsPopups;
var supportsPaymentRequestApi = require("./supports-payment-request-api");
exports.supportsPaymentRequestApi = supportsPaymentRequestApi;
