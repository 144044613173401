export class Topic {
    constructor(name, signed) {
        this.name = name;
        this.signed = signed;
        this.offset = '';
    }
    static parse(data) {
        const [content, signature] = data.split('--');
        if (!content || !signature)
            return null;
        const sub = JSON.parse(atob(content));
        if (!sub.c || !sub.t)
            return null;
        return new Topic(sub.c, data);
    }
}
