"use strict";
var isIos = require("./is-ios");
var isIosFirefox = require("./is-ios-firefox");
var webkitRegexp = /webkit/i;
function isWebkit(ua) {
    return webkitRegexp.test(ua);
}
function isIosChrome(ua) {
    return ua.indexOf("CriOS") > -1;
}
function isFacebook(ua) {
    return ua.indexOf("FBAN") > -1;
}
module.exports = function isIosSafari(ua) {
    ua = ua || window.navigator.userAgent;
    return (isIos(ua) &&
        isWebkit(ua) &&
        !isIosChrome(ua) &&
        !isIosFirefox(ua) &&
        !isFacebook(ua));
};
