"use strict";
var isEdge = require("./is-edge");
var isSamsung = require("./is-samsung");
var isDuckDuckGo = require("./is-duckduckgo");
var isOpera = require("./is-opera");
var isSilk = require("./is-silk");
module.exports = function isChrome(ua) {
    ua = ua || window.navigator.userAgent;
    return ((ua.indexOf("Chrome") !== -1 || ua.indexOf("CriOS") !== -1) &&
        !isEdge(ua) &&
        !isSamsung(ua) &&
        !isDuckDuckGo(ua) &&
        !isOpera(ua) &&
        !isSilk(ua));
};
